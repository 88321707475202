.hero {
  height: 500px;
  background-size: cover;
  background-position: 0 20%;
  position: relative;
}

.hero::after {
  content: "";
  display: block;
  opacity: .4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
}

.hero * {
  position: relative;
  z-index: 2;
}

.hero h1, .hero h2 {
  color: white;
}

.hero h1 {
  font-size: 60px;
  padding-top: 140px;
  margin-top: 0;
}
